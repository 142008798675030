















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { QuizQuestionSetInfo, FeaturedQuizQuestionSet, SortOrderList, Api, Locale } from '@/edshed-common/api'
import { locales } from '@/edshed-common/i18n'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'

@Component({ name: 'FeaturedQuizzes' })
export default class FeaturedQuizzes extends Mixins(ModalMixin) {
  private locales = locales

  private showAddQuizModal: boolean = false

  private quizToFeatureIdent = ''
  private quizToFeatureLocale: Locale = 'en_GB'
  private quizToFeatureDetails: QuizQuestionSetInfo | null = null
  private confirmedMismatchLocales: boolean = false
  private featuredQuizPlaceholderText: string = ''

  private selectedLocale: Locale = 'en_GB'

  private results: FeaturedQuizQuestionSet[] = []

  private recordToChangeSortOrder: Partial<FeaturedQuizQuestionSet> | null = null
  private updatedSortOrder: number = 0
  private maxSortOrder: number = 0
  private draggingRow: Partial<FeaturedQuizQuestionSet> | null = null
  private draggingRowIndex = 0

  public mounted () {
    this.getData()
  }

  @Watch('quizToFeatureIdent')
  private onQuizToFeatureIdentChanged () {
    this.quizToFeatureDetails = null
    this.confirmedMismatchLocales = false
    this.featuredQuizPlaceholderText = ''

    if (this.quizToFeatureIdentValid) {
      this.featuredQuizPlaceholderText = 'Loading...'
      Api.getQuestionSet(this.quizToFeatureIdent)
        .then((quiz) => {
          this.quizToFeatureDetails = quiz
        }).catch(() => {
          this.featuredQuizPlaceholderText = 'Not found'
        })
    }
  }

  private get quizToFeatureLocalesMatch () {
    return this.quizToFeatureDetails && this.quizToFeatureDetails.locale === this.quizToFeatureLocale
  }

  private get quizToFeatureReadyToAdd () {
    return this.quizToFeatureDetails && (this.quizToFeatureLocalesMatch || this.confirmedMismatchLocales)
  }

  private get quizToFeatureIdentValid () {
    return (this.quizToFeatureIdent.length > 0 && this.quizToFeatureIdent[0] === 'Q')
  }

  private localeName (locale: Locale) {
    return this.locales.find(l => l.underscored === locale)?.name
  }

  private sequentialiseSortOrders () {
    this.locales.forEach((locale) => {
      this.results.filter(r => r.locale === locale.underscored).forEach((featuredQuiz, index) => {
        featuredQuiz.sort_order = index
      })
    })
  }

  private async getData () {
    try {
      const results = await Api.getFeaturedQuestionSets({})
      if (results) {
        this.results = results
        this.sequentialiseSortOrders()
      }
    } catch (error) {
      this.alert({
        title: 'Error',
        message: error as string
      })
    }
  }

  private async deleteQuizFeature (row: FeaturedQuizQuestionSet) {
    try {
      if (await this.deleteConfirm({ name: '' }, {
        title: 'Unfeature Quiz',
        message: 'Are you sure you want to unfeature this quiz?',
        customLabel: 'Unfeature'
      })) {
        await Api.deleteFeaturedQuestionSet(row.id)

        setTimeout(() => {
          this.getData()
        }, 100)
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }
  }

  private async addFeaturedQuiz () {
    if (!this.quizToFeatureDetails) {
      return
    }

    try {
      await Api.addFeaturedQuestionSet({
        quiz_ident: this.quizToFeatureIdent,
        locale: this.quizToFeatureLocale
      })
    } catch (err: unknown) {
      if (err instanceof Error) {
        await this.alert({
          title: 'Error',
          message: err.message
        })
      }
    }

    setTimeout(() => {
      this.getData()
      this.closeAddQuizModal()
    }, 100)
  }

  private addQuizClicked () {
    this.quizToFeatureIdent = ''
    this.quizToFeatureLocale = this.selectedLocale
    this.showAddQuizModal = true
  }

  private closeAddQuizModal () {
    this.showAddQuizModal = false
  }

  get featuredQuizzes () {
    if (!this.results) {
      return []
    }
    return this.results.filter(quiz => quiz.locale === this.selectedLocale).sort() || []
  }

  private async changeSortOrder (droppedOnIndex: number, movedIndex: number, movedRow: Partial<FeaturedQuizQuestionSet>) {
    if (movedIndex === droppedOnIndex) { return }

    // moved item gets sort order of row dropped on
    movedRow.sort_order = droppedOnIndex

    if (movedIndex > droppedOnIndex) {
      // droped on and above get a new sort order of index + 2
      for (let index = droppedOnIndex; index < movedIndex; index++) {
        this.results[index].sort_order = index + 1
      }
    } else {
      // next item after moved until before old each decr
      for (let index = movedIndex + 1; index <= droppedOnIndex; index++) {
        this.results[index].sort_order = index - 1
      }
    }

    const apiPayload: SortOrderList = {}

    this.results.forEach((item) => {
      if (item.id) {
        apiPayload[item.id] = item.sort_order || 0
      }
    })

    if (apiPayload) {
      try {
        await Api.applyFeaturedQuizzesSortOrders(apiPayload)
        this.getData()
      } catch (error: unknown) {
        if (error instanceof Error) {
          await this.alert({
            title: 'Error',
            message: `Item's position could not be saved - ${error.message}`
          })
        }
      }
    }
  }

  private dragstart (payload) {
    this.draggingRow = payload.row
    this.draggingRowIndex = payload.index
    payload.event.dataTransfer.effectAllowed = 'copy'
  }

  private dragover (payload) {
    payload.event.dataTransfer.dropEffect = 'copy'
    payload.event.target.closest('tr').classList.add('is-selected')
    payload.event.preventDefault()
  }

  private dragleave (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    payload.event.preventDefault()
  }

  private drop (payload) {
    payload.event.target.closest('tr').classList.remove('is-selected')
    const droppedOnIndex = payload.index
    const movedIndex = this.draggingRowIndex

    if (this.draggingRow) {
      this.changeSortOrder(droppedOnIndex, movedIndex, this.draggingRow)
    }
  }
}
